import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import Departamentos from '../API/Departamentos';
import '../tools/css/Detalle_compra.css';
import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import icono_aprobado from '../tools/images/aprobado icono.svg'
import icono_rechazada from '../tools/images/rechazado icono.svg'
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi.azurewebsites.net/";


var proceso=null;
var estado=null
var mensaje='';

var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var id_orden = urlParams.get('id');
var valor = 0;
var detalle_compra='';
var banco='';
var id_comprador='';
var nom_comprador='';

var id_cod_promo=0;
var estado_cuen=false;
var estado_transaccion='';


const body = {
    background: '#ffffff',
    height: 'auto'
  };
  



class Demonio_MedicallCommerce extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Detalle_orden:[],
        Banco:[],
        Comprador:[],
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_activacuenta: true,
        button_activacuenta: false,
          form:{   
          tipo_id:'',
          dv:'',  
          id_inst:'',
          nom_inst:'',
          email_inst:'',
          clase_pres:'',
          cod_pres:'',
          tipo_naturaleza:'',
          nivel:'',
          ese:'no',
          cod_depa:'',
          cod_muni:'',
          dir_inst:'',
          tele_inst:'',
          tele_inst2:'',
          pag_web:'',
          tipo_idrl:'',
          id_rl:'',
          nom_rl:'',
          pass:'',
          pass_confirm:'',
          selectedFile:null,
          selectedFilep:null,
          cod_activa:'',
          municipio:'',
          btnpaso1: <button id="btn_save" className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                      </svg>
                    </button>
          }
          
        }

    }

 

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    
    
    } 
    
    


    componentDidMount(){
      $('#label_razonsocial').hide(); 
      $('#link_panelweb').hide();
      $('#vista_imgs').hide();
      $('#vista_imgs1').hide();
      $('#vista_imgs2').hide();
      //this.Consultar_id_transaccion_commercemedicall()

      this.timerID = setInterval(
        () => this.Consultar_transacciones_pendientes(),
        180000
      );
      

      //alert(id_orden)
      //this.Detalle_idorden()
    }  

    empty_page_hide(){
      $('#detalle_compra_true').hide();     
      $('#detalle_compra_false').hide();
      
    }


    Consultar_transacciones_pendientes=async()=>{ 

      var settings = {
        "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/id_compras_pendientes?select=*",
        "method": "GET",
        "timeout": 0,
        "headers": {
          "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
          "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M"
        },
      };
      
      $.ajax(settings).done(function (response) {
        console.log(response[0].string_agg);
        var id_transa =response[0].string_agg.split(",")
        //alert(id_transa)

          for (var i = 0; i < id_transa.length; i++) {
            //alert(id_transa[i])

            // consulta id_transaccion API Medicall24

                var settings = {
                  "url": "https://medicallapi.azurewebsites.net/api/Payments/GetStatusOrder/"+id_transa[i],
                  "method": "GET",
                  "timeout": 0,
                  "headers": {
                    "Cookie": "ARRAffinity=a23520db97941b3f5b5acd1c839841d3af60115c5c87015f2bcbb95ebd0cda18; ARRAffinitySameSite=a23520db97941b3f5b5acd1c839841d3af60115c5c87015f2bcbb95ebd0cda18"
                  },
                };
                
                $.ajax(settings).done(function (response) {
                  console.log(response.data);
                  var est_transaccion=response.data.status
                  var idtransaccion=response.data.id
                 

                  //alert(response.data.order.status)

                    if(est_transaccion=='APPROVED'){

                        //alert("Se envia el correo")

                        var settings = {
                          "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/reporte_general_compras?id_transaccion=eq."+idtransaccion+"&select=*",
                          "method": "GET",
                          "timeout": 0,
                          "headers": {
                            "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                            "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M"
                          },
                        };
                        
                        $.ajax(settings).done(function (response) {
                          console.log(response[0]);

                          var idcodpromo=response[0].id_codigo
                          var correo_comprador=response[0].email_comprador
                          //alert(correo_comprador)
                          var nom_producto=response[0].nom_producto
                          var insititucion=response[0].nombre_institucion
                          var tel_insitucion=response[0].telefono_institucio
                          var dir_institucion=response[0].direccion_institucion
                          var ciudad=response[0].ciudad_institucion
                          var departamento=response[0].dpto_institucion
                          var pais=response[0].pais_institucion
                          var linkpasos=response[0].link_pasos
                          var linkayuda=response[0].link_ayuda
                          var linkterminos=response[0].link_terminos
                         
                          var settings = {
                            "url": "https://edutlasdeveloper.pythonanywhere.com/send-email",
                            "method": "POST",
                            "timeout": 0,
                            "headers": {
                              "Content-Type": "application/json"
                            },
                            "data": JSON.stringify({
                              "email_to": correo_comprador,
                              "producto": nom_producto,
                              "link_aliados": "https://appmedicall24.com/aliados",
                              "link_pqrd": "https://medicall24.com.co/pqrs",
                              "razon_social": insititucion,
                              "telefono": tel_insitucion,
                              "direccion": dir_institucion,
                              "ciudad": ciudad,
                              "departamento": departamento,
                              "pais": pais,
                              "link_pasos": linkpasos,
                              "link_ayuda": linkayuda,
                              "link_terminos": linkterminos
                            }),
                          };
                          
                          $.ajax(settings).done(function (response) {
                            console.log(response);

                            var settings = {
                              "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/registro_compra?id_transaccion=eq."+idtransaccion,
                              "method": "PATCH",
                              "timeout": 0,
                              "headers": {
                                "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                                "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                                "Content-Type": "application/json"
                              },
                              "data": JSON.stringify({
                                "estado_transaccion": est_transaccion,                                
                                "estado_cuenta": true
                              }),
                            };
                            
                            $.ajax(settings).done(function (response) {
                              console.log(response);                              

                              var settings = {
                                "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/codigo_promo?id_codigo=eq."+idcodpromo+"&select=*",
                                "method": "GET",
                                "timeout": 0,
                                "headers": {
                                  "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                                  "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M"
                                },
                              };
                              
                              $.ajax(settings).done(function (response) {
                                console.log(response[0]);                     
                    
                                  var cuenta_compra_actual=response[0].cuenta_compra
                    
                                  var settings = {
                                    "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/codigo_promo?id_codigo=eq."+idcodpromo,
                                    "method": "PATCH",
                                    "timeout": 0,
                                    "headers": {
                                      "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                                      "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                                      "Content-Type": "application/json"
                                    },
                                    "data": JSON.stringify({
                                      "cuenta_compra": cuenta_compra_actual+1
                                    }),
                                  };
                                  
                                  $.ajax(settings).done(function (response) {
                                    console.log(response);
                    
                                    
                                  });                             
                    
                              });
                             
                            });

                          });                
                          
                          
                        });


                    }
                });

            
          
          }

      });

    }


   
    Consultar_id_transaccion_commercemedicall=async(id_orden)=>{    
      
        var settings = {
          "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/registro_compra?id_transaccion=eq."+id_orden+"&select=*",
          "method": "GET",
          "timeout": 0,
          "headers": {
            "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
            "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M"
          },
        };
        
        $.ajax(settings).done(function (response) {
          //console.log(response[0]);

          id_cod_promo=response[0].id_codigo_promo
          estado_cuen=response[0].estado_cuenta
          estado_transaccion=response[0].estado_transaccion
          //console.log(id_cod_promo)
          //console.log(estado_cuen)
          //console.log(estado_transaccion)

          
          
        });
    
      }

      Actualizar_id_transaccion_commercemedicall=async(estado_transa,ip_transa)=>{    
      
          var settings = {
            "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/registro_compra?id_transaccion=eq."+id_orden,
            "method": "PATCH",
            "timeout": 0,
            "headers": {
              "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
              "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
              "Content-Type": "application/json"
            },
            "data": JSON.stringify({
              "estado_transaccion": estado_transa,
              "ip_transaccion": ip_transa
            }),
          };
          
          $.ajax(settings).done(function (response) {
            console.log(response);
           
          });
          
          this.Consultar_codigo_promo_commercemedicall();
      }


      Consultar_codigo_promo_commercemedicall=async()=>{    
      
          var settings = {
            "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/codigo_promo?id_codigo=eq."+id_cod_promo+"&select=*",
            "method": "GET",
            "timeout": 0,
            "headers": {
              "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
              "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M"
            },
          };
          
          $.ajax(settings).done(function (response) {
            //console.log(response[0]);

            if(estado_transaccion=='aprobada  ' && estado_cuen==false){

              //Update cuenta_compra codigo promocional

              var cuenta_compra_actual=response[0].cuenta_compra

              var settings = {
                "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/codigo_promo?id_codigo=eq."+id_cod_promo,
                "method": "PATCH",
                "timeout": 0,
                "headers": {
                  "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                  "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                  "Content-Type": "application/json"
                },
                "data": JSON.stringify({
                  "cuenta_compra": cuenta_compra_actual+1
                }),
              };
              
              $.ajax(settings).done(function (response) {
                console.log(response);

                var settings = {
                  "url": "https://ukvjbkxaqzltngpbrdfi.supabase.co/rest/v1/registro_compra?id_transaccion=eq."+id_orden,
                  "method": "PATCH",
                  "timeout": 0,
                  "headers": {
                    "apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrdmpia3hhcXpsdG5ncGJyZGZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyODE5NTcsImV4cCI6MjA1MTg1Nzk1N30.3CrdWxYa5Rme_1Zbhi3peYRfsA35j4eUnPZyWlMsQ1M",
                    "Content-Type": "application/json"
                  },
                  "data": JSON.stringify({                   
                    "estado_cuenta": true
                  }),
                };
                
                $.ajax(settings).done(function (response) {
                  console.log(response);
                 
                });
              });

            }           

          });
    
      }


    Detalle_idorden=async()=>{

      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })

      axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+id_orden,

        )  
      .then(response=>{
        //console.log(response.data)

        this.setState({Detalle_orden:response.data.order})

        proceso=response.data.data.status;
       
        mensaje  =response.data.message;      

        valor=response.data.data.amount_in_cents/100

        nom_comprador=this.state.Detalle_orden.names.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ this.state.Detalle_orden.lastNames.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))

        valor=(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(valor));

        if(proceso==='APPROVED'){
          estado='Aprobada'
          
      }else{
        if(proceso==='DECLINED'){
          estado='Rechazada'
        }
      }

        if(proceso==='APPROVED'){
          $('#detalle_compra_false').hide();
          $('#detalle_compra_true').show();
        }else{
          $('#detalle_compra_false').show();
          $('#detalle_com pra_true').hide();
        }     

        if(response.data.order.type==='plan-personal' || response.data.order.type==='cuota-moderadora'){
          $('#btn_finalizarcompra').hide();
        }else{

          $('#btn_finalizarcompra').show();

        }
      

        this.setState({ loading: this.state.loading = true })

        this.Actualizar_id_transaccion_commercemedicall(response.data.order.status,response.data.order.paymentRemoteIP)
         
        
      })
      .catch(error=>{
        console.log(error.response);
        

        this.setState({Detalle_orden:error.response.data.order})       
      
        proceso=error.response.data.data.status;
       
        mensaje=error.response.data.message;
      
        valor=error.response.data.data.amount_in_cents/100

        valor=(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(valor));

        nom_comprador=this.state.Detalle_orden.names.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ this.state.Detalle_orden.lastNames.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))

        if(proceso==='APPROVED'){
          estado='Aprobada'
          
      }else{
        if(proceso==='DECLINED'){
          estado='Rechazada'
        }
      }

        if(proceso==='DECLINED'){
            $('#detalle_compra_false').show();
            $('#detalle_com pra_true').hide();
        }else{
         
        } 
        
        if(error.response.data.order.type==='plan-personal' || error.response.data.order.type==='cuota-moderadora'){
          $('#btn_finalizarcompra_false').hide();
        }else{

          $('#btn_finalizarcompra_false').show();

        }      

        this.setState({ loading: this.state.loading = true })
     
    
    })
  
  
    } 

    imprimir_pagina(){
      window.print(); 
    }

    pagina_principal(){
      window.location.href="/perfil";
    }


   render(){  
      const {form} = this.state;

      return(      
        
       
        <div style={body} className=""> 

                <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                
               
                 
                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                  
    
                </nav>
         
                

         
              <footer className="d-flex justify-content-center" id="footer" >
                  <strong>Copyright &copy; 2020 Medicall24  <a href="http://adminlte.io"> Terminos y condiciones</a> - </strong>
                     Todos los derechos reservados. 
                     <b> Version </b> 2.0.4
                    <div class="float-right d-none d-sm-inline-block">            
                    </div>
              </footer>


              


              

    
          

        </div>
         
      )

  }  
  
  
}






export default Demonio_MedicallCommerce;
import React, { Component, useEffect } from 'react';
import {LocalStorage} from './LocalStorage';
import * as ReactBootStrap from 'react-bootstrap';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


import panel_logo_login from '../tools/images/panel_logo_login.svg';
import nit from '../tools/images/panel_identificador_prestador.svg'
import email from '../tools/images/panel_correo_electronico.svg'
import pass from '../tools/images/panel_contraseña.svg'
import '../tools/css/Login.css';
import axios from 'axios';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

const ApiURL="https://medicallapi.azurewebsites.net/";

var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var id_membresia = urlParams.get('code');
var mensaje='';

class Login extends Component {

   
   constructor()
    {
     super()
      this.state={
        loading_button: true,
        active_button: true,
        loading_buttonrecovery_pass: true,
        active_buttonrecovery_pass: true,
        loading_buttonchange_pass: true,
        active_buttonchange_pass: true,
        loading_buttonactivacuenta: true,
        active_buttonactivacuenta: true,
        form:{   
          nit_institucion:'',
          username: '',
          passwor: '',
          correo_recupera:'',
          cod_recuperacion:'',
          pass:'',
          pass_confirm:'',
          cod_activacion:'',
          cod_activa1:'',
          cod_activa2:'',
          cod_activa3:'',
          cod_activa4:'',
          cod_activa5:'',
          cod_activa6:'',
          }              
      }     
    }

   handleChange=async e=>{
    await this.setState({
       form:{
            ...this.state.form,
            [e.target.name]: e.target.value
            }
       })
       console.log(this.state.form);
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }


    modal_recoverypass(){
      $('#mensaje_recupera').show();
      $('#modal-recoverypass').modal('show');
      $("#modal-recoverypass input").val("");
      $('#cambio_contraseña').hide();
      $('#correo_recuperacion').show();
      $('#btns_option').show();
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }

    modal_activacuenta(){      
      $('#modal-activacuenta').modal('show');
      $("#modal-activacuenta input").val("");
      
      //this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }

    validar_codigo(){
      $('#mensaje_recupera').hide();
      $('#cambio_contraseña').show();
      $('#correo_recuperacion').hide();
      $('#btns_option').hide();     
    }

    
    validar_recaptcha = (p) => {
      alert("grecaptcha is ready!");
    }

    componentDidMount(){

      $('#cambio_contraseña').hide();

      if(sessionStorage.getItem('token')){
        window.location.href="/inicio";
      }

    }

    mostrarPassword(){
      var cambio = document.getElementById("txtPassword");
		if(cambio.type == "password"){
			cambio.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    }



   iniciarSesion=async()=>{

    if(navigator.onLine) {
     
      if(this.state.form.nit_institucion.length==0 || this.state.form.username.length==0 || this.state.form.passwor.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
  
        await axios.post(ApiURL+"/api/Auth/LoginAdmin",
  
          {
          nit:this.state.form.nit_institucion,
          email: this.state.form.username.toLowerCase(),
          password: this.state.form.passwor
          })  
  
        .then(response=>{
          console.log(response)
          //var  status  = response.status;
          //alert(status); 
          //mensaje  = response.data.message;
                
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
         
                var  token  = response.data.token;
                var  id_institution= response.data.institution.id;
                var  institution  = response.data.institution.name;
                var  nombre_usua  = response.data.user.name1 ? response.data.user.name1 : ' ' +" "+response.data.user.name2 ? response.data.user.name2 : ' ' + " "+response.data.user.lastname1 ? response.data.user.lastname1 : ' '+" "+response.data.user.lastname2 ? response.data.user.lastname2 : ' ';
                var  avatar  = response.data.institution.avatar;
                //alert(nombre_usua); 
                sessionStorage.setItem('token',token)
                sessionStorage.setItem('id_institution',id_institution)
                sessionStorage.setItem('institution',institution)
                sessionStorage.setItem('nombre_usua',nombre_usua)
                sessionStorage.setItem('avatar',avatar)
                sessionStorage.setItem('codigo',id_membresia)
  
                window.location.href="/inicio";            
                       
                
                
           
          //var  dato  = response.data.token;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          
        })
        .catch(error=>{
  
          if (error.response) {
           
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);

      
       })
        
      }

    } else {
  
      store.addNotification({
        title: 'Atención',
        message: 'Compruebe su conexión a internet e intente nuevamente',
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })
     
    }

        

   }

   _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
     
      this.iniciarSesion()
      
    }
  }

  _tab1 = (t) => {
    this.Campo_numerico();
    if (t.key === 'Tab' || t.key === 'Enter') {        
      
      document.getElementById("input1").focus();
      
      
      //alert("enter");
      
    }
  }

  _tab2 = (t) => {
    if (t.key === 'Tab' || t.key === 'Enter') {        
      
      document.getElementById("txtPassword").focus();
      
      //alert("enter");
      
    }
  }

  



   Recuperar_cuenta=async()=>{

    if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.correo_recupera)){

      store.addNotification({
        title: 'Atención',
        message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = false })
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
      await axios.post(ApiURL+"/api/Auth/RecoveryPassword",

        {         
          email: this.state.form.correo_recupera          
        })  

      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
        this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })

        //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
        
        $('#mensaje_recupera').hide();
        $('#cambio_contraseña').show();
        $('#correo_recuperacion').hide();
        $('#btns_option').hide();
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
          this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })

        $('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

    }      

   }


   Cambiar_Contraseña=async()=>{

    if(this.state.form.cod_recuperacion.length==0 || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ){

      store.addNotification({
        title: 'Atención',
        message: "Faltan datos por ingresar",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      if(this.state.form.pass!==this.state.form.pass_confirm){

        store.addNotification({
          title: 'Atención',
          message: 'La contraseña ingresada no es correcta',
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

      }else{
      
      this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = false })
      this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = false })    

      await axios.post(ApiURL+"/api/Users/ChangePassword", 

        {
          validationCode:this.state.form.cod_recuperacion,         
          password: this.state.form.pass          
        })
 
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })

        //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
  
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })

        //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

     }

    }      

   }


   Activar_cuenta=async()=>{

    if(this.state.form.cod_activa1=='' || this.state.form.cod_activa2=='' || this.state.form.cod_activa3=='' || this.state.form.cod_activa4=='' || this.state.form.cod_activa5=='' || this.state.form.cod_activa6=='' ){

      store.addNotification({
        title: 'Atención',
        message: "Ingresa un código de activación valido",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      
      
      this.setState({ loading_buttonactivacuenta: this.state.loading_buttonactivacuenta = false })
      this.setState({ active_buttonactivacuenta: this.state.active_buttonactivacuenta = false })    

      await axios.post(ApiURL+"/api/Users/ActivateAccount", 

        {
          type:"Activation",         
          validationCode: this.state.form.cod_activa1+this.state.form.cod_activa2+this.state.form.cod_activa3+this.state.form.cod_activa4+this.state.form.cod_activa5+this.state.form.cod_activa6          
        })
 
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ loading_buttonactivacuenta: this.state.loading_buttonactivacuenta = true })
        this.setState({ active_buttonactivacuenta: this.state.active_buttonactivacuenta = true }) 

        $('#modal-activacuenta').modal('hide');
        $("#modal-activacuenta input").val("");
        //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
  
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_buttonactivacuenta: this.state.loading_buttonactivacuenta = true })
          this.setState({ active_buttonactivacuenta: this.state.active_buttonactivacuenta = true })

        //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_buttonactivacuenta: this.state.loading_buttonactivacuenta = true })
          this.setState({ active_buttonactivacuenta: this.state.active_buttonactivacuenta = true })
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_buttonactivacuenta: this.state.loading_buttonactivacuenta = true })
          this.setState({ active_buttonactivacuenta: this.state.active_buttonactivacuenta = true })
      }
      //console.log(error.config);
    
    })

    

    }      

   }


  render(){  

   return (    
   

      <div id="login" >          


            <div className="row">

                
                  <div className="col-md-6">
      
                      <div className="row"> 
                        <img id="image" src={panel_logo_login} alt="logo"  width= "280" className="align-center"  />
                      </div>             
                      
      
                  </div>
      
      
                  <div  className="col-md-6">

                  <div id="titulo" className="card-header p-0 pt-1 border-bottom-0 text-center">
                              <h4><b>Bienvenidos</b></h4>
                              <b>Portal para prestadores de salud</b>
                            </div>
      
                      <div id="card_login" className="card">
      
                
                                        
                                            <div id="card_body" className="card-body">

                                              <br></br>
                          
                                                <div className="form-group">
                                                      <div className="input-group">                          
                                                          <div className="input-group-append">
                                                              <button id="icono" className="btn btn-primary form-control-md" type="button"> <span className="far fa-hospital fa-lg"></span> </button>
                                                          </div>                         
                                                          <input id="input" type="text" maxLength="10" className="form-control form-control-md camponum" placeholder="Identificación del prestador" name="nit_institucion" onChange={this.handleChange} onKeyDown={this._tab1}/>                                  
                                                      </div>
                                                </div>                                
                                                                
                                                <div className="form-group">
                                                      <div className="input-group">   
                                                          <div className="input-group-append">
                                                               <button id="icono" className="btn btn-primary form-control-md" type="button" > <span className="far fa-envelope fa-lg"></span> </button>
                                                          </div>                                                                          
                                                          <input id="input1" type="email" className="form-control form-control-md" aria-describedby="emailHelp" placeholder="Correo electrónico" name="username" onChange={this.handleChange} onKeyDown={this._tab2} />                                  
                                                      </div>
                                                </div>
                                                                  
                                                <div className="form-group">
                                                      <div className="input-group">
                                                          <div className="input-group-append">
                                                                <button id="icono" className="btn btn-primary form-control-md" type="button"> <span className="fas fa-lock fa-lg"></span> </button>
                                                          </div>
                                                          <input id="txtPassword" type="password" className="form-control form-control-md" placeholder="Contraseña" name="passwor" onChange={this.handleChange} onKeyDown={this._handleKeyDown}/>   
                                                            <div className="input-group-append">
                                                                <button id="show_password" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Mostrar/Ocultar contraseña" onClick={()=> this.mostrarPassword()}> <span class="fa fa-eye-slash icon"></span> </button>
                                                            </div>                               
                                                      </div>
                                                </div> 

                                               
                                               
                                                <div className="input-group"> 
                                                      <div className="input-group-addon"></div>
                                                       <button type="submit" id="btn-ttc" className="btn"  disabled={!this.state.active_button} onClick={()=> this.iniciarSesion()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}
                                                        Iniciar sesión
                                                       </button>
                                                      
                                                </div>

                                                <br></br>  
                                               
                                                <div className="col-md-12 text-center">
                                                  <a id="btn-registrarse" href="/crear_cuenta" target="_blank"> Registrarse </a>
                                                </div>

                                                <div className="col-md-12 text-center">
                                                <button id="btn-recoveripass" onClick={()=> this.modal_recoverypass()}>¿Olvidó su contraseña?</button> 
                                                </div>

                                                <div className="col-md-12 text-center">
                                                <button id="btn-activarcuenta" onClick={()=> this.modal_activacuenta()}><b>Activar cuenta</b></button> 
                                                </div>
                          
                          
                                            </div>
                              
                       
                      </div>
      
                  </div>

            </div>

            <div className="modal fade" id="modal-recoverypass">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header"id="fondo_modal_compra" >
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Recuperar contraseña</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 Se enviará un código de recuperación al correo electrónico registrado. <strong> {this.state.form.email_inst} </strong> 

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row" id="correo_recuperacion">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Ingrese el correo:</label>
                                          <input type="text" className="form-control" maxLength="50" name="correo_recupera" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>                          
                          
                          </div>

                          <div id="cambio_contraseña">

                          <div className="row">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Código de validación:</label>
                                          <input type="text" className="form-control" maxLength="6" name="cod_recuperacion" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Nueva contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="pass" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>  

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Confirmar contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="pass_confirm" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div> 

                              <div className="col-md-12 text-center">
                                  <button type="submit" id="btn-cambiocontra" className="btn"  disabled={!this.state.active_buttonchange_pass} onClick={()=> this.Cambiar_Contraseña()}> {this.state.loading_buttonchange_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Cambiar</button>                     
                              </div>

                          </div> 

                          </div>        
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between" id="btns_option">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.active_buttonrecovery_pass} onClick={()=> this.Recuperar_cuenta()}> {this.state.loading_buttonrecovery_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Recuperar contraseña </button>           
                    
                          <button type="button" className="btn btn-primary" onClick={()=> this.validar_codigo()}>  Tengo un código </button>            
              
                             
                    </div>

                  </div>
                  
                </div>
                
            </div>


            <div className="modal fade" id="modal-activacuenta">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Activar cuenta</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">                       

                          <div className="row">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Ingresa el código de activación:</label>
                                      
                                  </div>          
                              </div>

                          </div> 

                          <div className="row justify-content-md-center"> <br/>                                      
                              
                                 
                              <input type="text" className="form-control" maxLength="1" name="cod_activa1" id="cod_activa" onChange={this.handleChange}/>
                              <input type="text" className="form-control" maxLength="1" name="cod_activa2" id="cod_activa" onChange={this.handleChange}/>    
                              <input type="text" className="form-control" maxLength="1" name="cod_activa3" id="cod_activa" onChange={this.handleChange}/>
                              <input type="text" className="form-control" maxLength="1" name="cod_activa4" id="cod_activa" onChange={this.handleChange}/>
                              <input type="text" className="form-control" maxLength="1" name="cod_activa5" id="cod_activa" onChange={this.handleChange}/>
                              <input type="text" className="form-control" maxLength="1" name="cod_activa6" id="cod_activa" onChange={this.handleChange}/>                                              
                             
              
                          </div>

                          <div className="col-md-12 text-center"> <br/>
                                  <button type="submit" id="btn-activacuenta" className="btn"  disabled={!this.state.active_buttonactivacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_buttonactivacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar</button>                     
                          </div>
    
                              
                    </div> 
    
                  </div>
                  
                </div>
                
            </div>

</div>


  );
 }
}


export default Login;

import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Login_movil from './components/Login_movil';
import Navbar from './components/Navbar';
import NavbarHome from './components/NavbarHome';
import NavbarCita from './components/NavbarCita';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Sedes from './pages/Sedes';
import Convenios from './pages/Convenios';
import Citas from './pages/Citas';
import Citas_pacientes from './pages/Citas_pacientes';
import Agendas from './pages/Agendas';
import Consultas_urg from './pages/Consultas_urg';
import Consultas_programada from './pages/Consultas_programada';
import ContratosAdd from './pages/ContratosAdd.js';
import ContratosEdit from './pages/ContratosEdit.js';
import Perfil from './pages/Perfil.js';
import Publicaciones from './pages/Publicaciones';
import Publicaciones_prestador from './pages/Publicaciones_prestador';
import Ajustes from './pages/Ajustes.js';
import Contratos from './pages/Contratos';
import Consentimientos from './pages/Consentimientos';
import Cuota_Moderadora from './pages/Cuota_Moderadora';
import Crear_Usuarios from './pages/Crear_Usuarios';
import Servicios from './pages/Servicios';
import Horarios_Atencion from './pages/Horarios_Atencion';
import Crear_Cuenta from './pages/Crear_Cuenta';
import Cambiar_Contraseña from './pages/CambiarContraseña';
import Detalle_compra from './pages/Detalle_compra';
import Demonio_MedicallCommerce from './pages/Demonio_MedicallCommerce.js';
import Activar_membresia from './pages/Activar_membresia';
import Informes from './pages/Informes.js';
import Error_404 from './pages/Error404';
import './tools/css/App.css';
import Navbar2 from './components/Navbar2';
import icono from './tools/images/icono.svg';

import Pagos_sumitech from './pages/Pagos_sumitech.js';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


function App (){

    return (
        <BrowserRouter>        
            
            <Switch>
   
                 <Route exact path="/">
                 <ReactNotifications/>
                   <Login_movil/>
                 </Route>
                 
                 <Route  path="/home">
                   <ReactNotifications/>
                   <NavbarHome/>
                   <Sidebar/>
                   <Home/>
                   <Footer/>                   
                 </Route>            

                 <Route  path="/convenios">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Convenios/>
                   <Footer/>
                 </Route>

                 <Route  path="/citas">
                 <ReactNotifications/>
                   <NavbarCita/>
                   <Sidebar/>
                   <Citas/>
                   <Footer/>
                 </Route>

                 <Route  path="/citas/pacientes">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Citas_pacientes/>
                   <Footer/>
                 </Route>

                 <Route  path="/agendas">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Agendas/>
                   <Footer/>
                 </Route>

                 <Route  path="/consultas_urgencias">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Consultas_urg/>
                   <Footer/>
                 </Route>

                 <Route  path="/consultas_programadas">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Consultas_programada/>
                   <Footer/>
                 </Route>

                 <Route  path="/crear/contratos">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <ContratosAdd/>
                   <Footer/>
                 </Route>                

                 <Route  path="/perfil">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Perfil/>
                   <Footer/>
                 </Route>

                 <Route  path="/inicio">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Publicaciones/>
                   <Footer/>
                 </Route>

                 <Route  path="/publicaciones">
                   <ReactNotifications/>
                   <Navbar/>  
                   <Sidebar/>                
                   <Publicaciones_prestador/>
                   <Footer/>
                 </Route>

                 <Route  path="/ajustes">
                   <Navbar/>
                   <Sidebar/>
                   <Ajustes/>
                   <Footer/>
                 </Route>

                 <Route  path="/contratos">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Contratos/>
                   <Footer/>
                 </Route>

                 <Route  path="/editar/contratos">
                 <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <ContratosEdit/>
                   <Footer/>
                 </Route>

                 <Route  path="/consentimientos">
                  <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Consentimientos/>
                   <Footer/>
                 </Route>

                 <Route  path="/cuotasmoderadoras">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Cuota_Moderadora/>
                   <Footer/>
                 </Route>

                 <Route  path="/crear_usuarios">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Crear_Usuarios/>
                   <Footer/>
                 </Route>

                 <Route  path="/servicios">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Servicios/>
                   <Footer/>
                 </Route>

                 <Route  path="/horarios">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Horarios_Atencion/>
                   <Footer/>
                 </Route>

                 <Route  path="/sedes">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>
                   <Sedes/>
                   <Footer/>
                 </Route>

                 <Route  path="/informes">
                   <ReactNotifications/>
                   <Navbar/>
                   <Sidebar/>                  
                   <Informes/>
                   <Footer/>
                 </Route>

                  <Route  path="/crear_cuenta">         
                   <ReactNotifications/>
                   <Crear_Cuenta/>                   
                 </Route>

                 <Route  path="/pagos_sumitech">         
                   <ReactNotifications/>
                   <Pagos_sumitech/>                   
                  </Route>

                 <Route  path="/recover_password">         
                   <ReactNotifications/>
                   <Cambiar_Contraseña/>                   
                  </Route> 
 
                  <Route  path="/detalle_compra">                   
                    <Detalle_compra/>                   
                  </Route> 

                  <Route  path="/detalle_compra_commerce">                   
                    <Demonio_MedicallCommerce/>                   
                  </Route>
 
                  <Route  path="/activationMembership">         
                   <ReactNotifications/>
                   <Activar_membresia/>                   
                  </Route>

                  <Route  path="**">         
                   <ReactNotifications/>
                   <Error_404/>                   
                  </Route>
             
              
            </Switch>        
        </BrowserRouter>

    );
}

export default App;